<template>
  <!--  <el-tooltip :content="$t('system.enter_3_or_more_characters')"-->
  <!--              :disabled="!!((filter.dictionary && !filter.dictionary.isRemote) || searchPhrase.length > 2 || filter.value.length)"-->
  <!--              placement="bottom-start">-->
  <el-select
      :filterable="isRemote ? isRemote : true"
      :placeholder="isRemote ? $t('system.enter_3_or_more_characters') : $t('system.select')"
      :remote="isRemote"
      :remote-method="isRemote ? remoteMethod : () => {}"
      :size="size"
      :value="filter.value"
      @change="onSelect"
      class="w-100"
      clearable
      multiple
      style="z-index: 99999"
      v-model="filter.value"
  >
    <template slot="empty">
      <div class="el-select-dropdown__empty">
        {{
          showRemoteTip ? $t('system.enter_3_or_more_characters') : loading ? $t('system.loading') : $t('system.no_data')
        }}
      </div>
    </template>
    <el-option
        :key="option.id"
        :label="$t(option.name)"
        :value="option.id"
        v-for="option in filter.values"
    >
    </el-option>
  </el-select>
  <!--  </el-tooltip>-->
</template>

<script>
import filtersGeneratorMixin from '../mixin/filtersGeneratorMixin';
import functions from '../mixin/functions';

export default {
  name: 'fieldMultiSelect',
  mixins: [filtersGeneratorMixin, functions],
  data() {
    return {
      searchPhrase: '',
      loading: false,
      selectedValues: [],
    };
  },
  computed: {
    isRemote() {
      return !!(this.filter.dictionary && this.filter.dictionary.isRemote);
    },
    showRemoteTip() {
      return !!(this.isRemote && this.searchPhrase.length < 3 && !this.filter.value);
    },
  },
  created() {
    if (this.filter.value && !Array.isArray(this.filter.value)) {
      this.filter.value = [this.filter.value];
    }
  },

  methods: {
    unique(array) {
      const result = [];
      const map = new Map();
      for (const item of array) {
        if (!map.has(item.id)) {
          map.set(item.id, true);
          result.push({
            id: item.id,
            name: item.name,
          });
        }
      }
      return result;
    },
    onSelect(val) {
      // this.filter.values =
      //   this.unique([...this.filter.values, ...this.filter.values.filter(item => val.includes(item.id))]);
      console.log('val', val);
    },
    async remoteMethod(val) {
      this.searchPhrase = val;
      if (val.length > 2) {
        this.loading = true;
        const {data} = await this.fetchDictionary(
            `aberit_module_generator/get_dictionary_data?dictionary_name=${this.filter.dictionary.name}`,
            {search: val},
        );
        if (data) {
          this.filter.values = this.unique([
            ...data,
            ...this.filter.values.filter(item => this.filter.value.includes(item.id)),
          ]);
        }
        this.loading = false;
      } else {
        // this.filter.values = this.filter.values.filter(item => this.filter.value.includes(item.id))
      }
    },
  },
};
</script>
