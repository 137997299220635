import axios from 'axios';
// import _ from 'lodash'
import notification from '../../../../notification/notify';
// import notification from '../../../../notification/notify'

export default {
  // created() {
  //   EventBus.$on('POPOVER_OPENED', (componentName) => {
  //     if (componentName !== this.field.name) {
  //       this.show = false
  //     }
  //   })
  // },
  methods: {
    getUsersByProjectId(id) {
      if (id) {
        axios.get(`get_users_by_project/${id}`).then((response) => {
          this.dictionaries.users = response.data.data;
        }).then(() => {
          if (!this.dictionaries.users.find(item => item.id === this.data.user_id)) {
            this.data.user_id = null;
          }
        });
      } else {
        this.dictionaries.users = {};
        this.data.user_id = null;
      }
    },
    fetchDictionary(endpoint, params) {
      try {
        return axios.get(endpoint, {params});
      } catch (e) {
        notification.notify(
          this.$t('notify.error'),
          this.$t('system.can_not_load'),
          'error');
      }
      // axios.get(endpoint, {params}).then((response) => {
      //   this.dictionaries[dictionaryName] = response.data
      // }).catch(() => {
      //   notification.notify(
      //     this.$t('notify.error'),
      //     this.$t('system.can_not_load'),
      //     'error');
      // })
    },
  },
};
