export default {
  props: {
    filter: {
      type: Object,
    },
    size: {
      type: String,
      default: 'mini',
    },
  },
};
